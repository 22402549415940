$knock-react-text-color: #111;
$knock-react-inverted-text-color: #fff;
$knock-react-fade-color: #999;
$knock-react-highlight-color: #2eb88f;
$knock-react-alternate-highlight-color: #3fa9f5;
$knock-react-softer-highlight-color: rgba(189, 221, 255, 0.26);
$knock-react-border-color: #e6e6e6;
$knock-react-stripe-color: #f8f8f8;
$knock-react-header-color: #0095ff;
$knock-react-background-color: #f2f2f2;
$knock-react-input-background-color: #efefef;
$knock-react-danger-color: #f44336;
