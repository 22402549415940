@import './styles/knockReactColors';
@import './styles/knockReactFlex';
@import './styles/knockReactForms';

body {
  background-color: #f8f9fa !important;
  @media screen and (max-device-width: 600px) {
    overflow: hidden;
  }

  &.bookmarkToggleInProgress .bookmarkButton {
    opacity: 0.4;
    pointer-events: none;
  }

  .maintenance-notification {
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 5px;
    border: 1px solid #d43f3a;
    color: #d43f3a;

    i.fa {
      margin-right: 5px;
    }
  }
}

.knock-react-app {
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100vh - 64px);
  padding: 24px;
  @media screen and (max-width: 960px) {
    padding-bottom: 64px;
  }

  h1 {
    margin-top: 0;
  }

  .withCustomScrollbar {
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }
    
    &::-webkit-scrollbar-track {
      background-color: #f4f4f4;
      border-radius: 4px;
    }
    
    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.3);
      border-radius: 4px;
    }
    
    &::-webkit-scrollbar-thumb:hover {
      background-color: rgba(0, 0, 0, 0.5);
    }
    
    &::-webkit-scrollbar-track:horizontal {
      background-color: #f4f4f4;
    }
    
    &::-webkit-scrollbar-thumb:horizontal {
      background-color: rgba(0, 0, 0, 0.3);
      border-radius: 4px;
    }
    
    &::-webkit-scrollbar-thumb:horizontal:hover {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }

  table.knock-react-table {
    max-width: 100%;
    min-width: 100%;
    border-collapse: collapse;

    th {
      &.no-select {
        cursor: pointer;
        background: #f3f3f3;
        border-right: 1px solid #f3f3f3;
        &:hover,
        &.active {
          background: white;
          box-shadow: inset 0px 1px 5px rgba(#333, 0.2);
        }
      }
      .sort-icon {
        margin-right: 2px;
      }
    }

    th {
      &.no-select {
        cursor: pointer;
        background: #f3f3f3;
        border-right: 1px solid #f3f3f3;
        &:hover,
        &.active {
          background: white;
          box-shadow: inset 0px 1px 5px rgba(#333, 0.2);
        }
      }
      .sort-icon {
        margin-right: 2px;
      }
    }

    td,
    th {
      border-bottom: 1px solid #e1e4ff;
      padding: 6px;
      text-align: left;
      white-space: nowrap;
      min-width: 75px;
    }

    th:first-child,
    td:first-child {
      text-align: left;
    }

    tr:not(.bypass-alternating-color):nth-child(even) {
      background-color: $knock-react-softer-highlight-color;
    }

    tr.total-row {
      td {
        font-weight: bold;
        &:first-child {
          padding-left: 12px;
        }
      }
    }

    .inner-table-listing {
      font-size: 95%;
      min-width: 110px;
    }

    .inner-table-row {
      background-color: $knock-react-background-color;
    }

    .no-select {
      user-select: none;
    }
  }

  .data-name {
    a {
      overflow: hidden;
      max-width: 120px;
      white-space: nowrap;
      display: inline-block;
      text-overflow: ellipsis;
    }
  }

  table.sortable-table {
    th {
      span:hover {
        cursor: pointer;
      }
    }

    .loadMoreButton {
      cursor: pointer;
      color: blue;
      text-align: center;
    }
  }

  span[data-tip] {
    margin-left: 5px;
  }

  hr {
    margin-top: 20px;
  }

  textarea {
    min-width: 500px;
    min-height: 100px;
  }

  pre {
    background-color: $knock-react-background-color;
    padding: 10px;
    border-radius: 6px;
    border: 1px solid $knock-react-border-color;
  }
}

.calls-table {
  .non-bold {
    font-weight: normal;
  }

  .border-right {
    border-right: 1px solid;
  }
}

.sessions-table {
  th {
    font-size: smaller;
    font-weight: normal;
  }

  th:first-child,
  td:first-child {
    width: 175px !important;
    max-width: 175px !important;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  tr.child-row {
    background-color: #f2f2f2 !important;
  }

  tr.child-row td {
    font-weight: normal;
    font-size: smaller;
  }

  .actionable {
    cursor: pointer;
  }

  .actionable:hover {
    opacity: 0.75;
  }

  .show-more-container {
    padding: 10px;
  }

  .text-large {
    font-size: x-large;
  }

  .ucfirst:first-letter {
    text-transform: capitalize;
  }

  .mLeft15 {
    margin-left: 15px;
  }

  $elements: 12;
  @for $i from 1 to $elements {
    .show-first-#{$i}:nth-child(#{$i}) ~ .show-first-#{$i} {
      display: none;
    }
  }
}

.ad-spend-table {
  .ad-spend-source-details {
    &.month-row {
      font-weight: bold;
    }
  }

  .ad-spend-pricing-model {
    font-style: italic;
  }

  &.property-source-view {
    .source-name-container {
      margin: 5px;
      font-size: 20px;

      .source-name {
        font-weight: bold;
      }
    }
  }
}

.login-page-container {
  margin: auto;
  width: 300px;

  .login-field-label {
    width: 80px;
  }
}

.knock-react-print-only {
  display: none;
}

.knock-react-flex-align-center {
  align-items: center;
}

.sort-field-container {
  padding: 10px;
}

.sort-field {
  font-weight: bold;
  padding: 5px;
  cursor: pointer;
}

.tab-link-content {
  font-size: 16px;
  margin: 20px 0 10px;
  padding: 0 10px;
  border-bottom: 1px solid #e1e4ff;

  .tab-link {
    color: black;
    padding: 10px;
    min-width: 140px;
    text-align: center;
    display: inline-block;
    text-decoration: none;
    position: relative;

    &.tab-link-active {
      border: 1px solid #e1e4ff;
      border-bottom-color: white;
      border-radius: 5px 5px 0 0;
      &:after {
        content: '';
        width: 100%;
        height: 2px;
        position: absolute;
        bottom: -2px;
        background: white;
        left: 0;
      }
    }
  }
}

.space-efficient-table-container {
  font-size: 12px;

  td {
    overflow-x: hidden;
    text-overflow: ellipsis;
  }
}

.engagement-chart-container {
  display: flex;
  flex-direction: column;
  border-top: 1px solid;

  .property-chart-container {
    cursor: pointer;
  }

  .property-chart {
    display: flex;
    flex-direction: row;
    padding: 5px;
    font-size: 0.95em;

    .chart-label {
      flex: 1.5 0 0;
      text-align: right;
      width: 20%;
      margin-right: 10px;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:hover {
        overflow: visible;
        text-overflow: inherit;
      }
    }

    .chart-container {
      flex: 7 0 0;
      display: flex;

      .chart-fill {
        background: white !important;
        padding-left: 5px;

        font-weight: bold;
      }

      .engagement-score {
        width: 48px;
        display: flex;
        align-items: center;
      }

      &:hover {
        filter: opacity(80%);
      }
    }
  }

  .chart-stats-container {
    text-align: center;

    .chart-stat {
      padding: 5px;
    }
  }

  .score-9 {
    background-color: #3a7c24;
    color: #3a7c24;
  }

  .score-8 {
    background-color: #152859;
    color: #152859;
  }

  .score-7 {
    background-color: #244397;
    color: #244397;
  }

  .score-6 {
    background-color: #1343d0;
    color: #1343d0;
  }

  .score-5 {
    background-color: #1d75e7;
    color: #1d75e7;
  }

  .score-4 {
    background-color: #39a6ec;
    color: #39a6ec;
  }

  .score-3 {
    background-color: #91c8f0;
    color: #91c8f0;
  }

  .score-2 {
    background-color: #bfd9f5;
    color: #bfd9f5;
  }

  .score-1 {
    background-color: #f4b18b;
    color: #f4b18b;
  }

  .score-0 {
    background-color: #f45042;
    color: #f45042;
  }
}

.leading-border {
  border-left: 3px solid #000;
}

@media print {
  html,
  body {
    height: 100%;
  }

  html,
  body,
  div#root {
    overflow: visible;
  }

  table {
    font-size: 12px;
  }

  canvas {
    margin: auto;
  }

  .knock-react-toast-container {
    display: none;
  }

  .knock-react-app {
    position: static;
  }

  .knock-react-hide-print {
    display: none;
  }

  .knock-react-print-only {
    display: block;
  }

  .knock-react-vertical-navbar,
  .knock-react-app-navbar-col {
    display: none;
  }
}

.report-checkbox-filter {
  display: inline-block;
  span {
    font-size: 12px;
    margin-left: 2px;
    vertical-align: text-top;
  }
  input {
    margin: 5px 5px 5px 0;
  }
  padding: 7px;
}

div.report-filters-filter-container {
  min-width: fit-content;
}

div.report-filters-filter {
  & > span {
    font-size: 12px;
    margin-left: 2px;
  }
  .knock-react-button {
    margin: 0 10px;
    padding: 11px 15px;
  }
}

.buttonLink {
  box-shadow: 0 0 0 2px #ffffff, 0 0 2px 4px #3fa9f5;
  outline: 1px dotted transparent;
  outline-offset: 1px;
}

@media screen and (max-device-width: 600px) {
  .knock-react-app {
    .knock-react-table-wrapper {
      overflow-x: auto;
    }

    .knock-react-flex.knock-react-flex-no-collapse {
      flex-direction: row;
    }

    .knock-react-app-navbar-col {
      min-height: 50px;
    }

    .knock-react-vertical-navbar-show {
      position: fixed;
      z-index: 999999999;
    }
  }
}

.knock-react-print-title {
  color: #535353;
}
.knock-react-print-subtitle {
  color: #535353;
}
.knock-react-print-url {
  color: #3fa9f5;
  text-decoration: none;
}
