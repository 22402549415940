.knock-react-flex {
    display: flex;

    &.knock-react-flex-center {
        justify-content: center;
    }

    .knock-react-flex-margin {
        margin: 10px;
    }

    .knock-react-flex-row {
    }
}
