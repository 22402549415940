@import './knockReactColors';

input.knock-react-input {
    border: 1px solid $knock-react-border-color;
    border-radius: 6px;
    outline: none;
    min-height: 20px;
    background-color: $knock-react-input-background-color;
    padding: 2px;
}

button.knock-react-button {
    margin: 10px;
    min-width: 80px;
    padding: 8px 15px;
    border-radius: 6px;
    outline: none;
    background-color: $knock-react-alternate-highlight-color;
    color: $knock-react-inverted-text-color;
    border: none;

    &.danger {
        background-color: $knock-react-danger-color;
        &:hover {
            background-color: darken($knock-react-danger-color, 10%);
        }
    }

    &:hover {
        background-color: darken($knock-react-alternate-highlight-color, 10%);
        cursor: pointer;
    }

    &[disabled] {
        cursor: not-allowed;
        background-color: $knock-react-background-color;
        &:hover {
            background-color: $knock-react-background-color;
        }
    }
}

.knock-react-invalid-input {
    border: 1px solid $knock-react-danger-color;
}

.knock-react-form-hint {
    margin-left: 10px;
    margin-right: 10px;
}

.knock-react-form-hint-error {
    color: $knock-react-danger-color;
}

.knock-react-alert {
    max-width: 300px;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid $knock-react-border-color;
    background-color: $knock-react-inverted-text-color;
    border-radius: 6px;

    .fa.alert-fa {
        margin-right: 8px;
    }

    &.knock-react-alert-error {
        border: 1px solid $knock-react-danger-color;
        color: $knock-react-danger-color;
    }
}

.knock-react-table-row-highlight {
    background-color: lighten($knock-react-alternate-highlight-color, 25%);
}
